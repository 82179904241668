.bg-color{
    color:rgba(255, 255, 255, 0.7);
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    background: url("/images/footer_bg.png");
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    /* clip-path: polygon(0 0, 100% 0, 100% 53%, 0% 100%); */
    z-index: 1;
}

.bg-color11{
  color:rgba(255, 255, 255, 0.7);
  padding-top: 100px;
  /* padding-bottom: 100px; */
  position: relative;
  background: url("/images/footer_bg.png");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  /* clip-path: polygon(0 0, 100% 0, 100% 53%, 0% 100%); */
  z-index: 1;
}

.about-img__container{
    position: relative;
}

.about-img__container::before {
    content: "";
    position: absolute !important;
    top: -24px;
    top: -1.5rem;
    left: -27.2px;
    left: -1.7rem;
    width: 100%;
    height: 100%;
    outline: 8px solid rgba(255, 255, 255, 0.7);
    outline: 0.5rem solid rgba(255, 255, 255, 0.7);
    z-index: -99;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
  }
  .about-img__container:hover:before {
    top: 0;
    left: 0;
  }

  .my-shadow{

    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }

  .title-shadow{
    text-transform: uppercase;
    font-family: 'Gruppo', cursive;
    letter-spacing: 1px;
}

  .my-image{

    width: 100% !important;
    max-height: 60vh;
    object-fit: cover;
  
    /* clip-path: polygon(3% 0, 7% 1%, 11% 0%, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0%, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0%, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0% 94%, 1% 89%, 0% 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0% 64%, 0% 61%, 0% 59%, 1% 54%, 0% 49%, 1% 45%, 0% 40%, 1% 37%, 0% 34%, 1% 29%, 0% 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%);  */

  }

  .desc-text{

    font-family: 'Source Sans Pro';

  }

  hr{
    border: 1px solid rgba(255, 255, 255, 0.7);;
  }

  .ragged {

    background-color: white;
    color: black;
    clip-path: polygon(3% 0, 7% 1%, 11% 0%, 16% 2%, 20% 0, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 39% 3%, 41% 1%, 45% 0%, 47% 2%, 50% 2%, 53% 0, 58% 2%, 60% 2%, 63% 1%, 65% 0%, 67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0, 82% 1%, 85% 0, 87% 1%, 89% 0, 92% 1%, 96% 0, 98% 3%, 99% 3%, 99% 6%, 100% 11%, 98% 15%, 100% 21%, 99% 28%, 100% 32%, 99% 35%, 99% 40%, 100% 43%, 99% 48%, 100% 53%, 100% 57%, 99% 60%, 100% 64%, 100% 68%, 99% 72%, 100% 75%, 100% 79%, 99% 83%, 100% 86%, 100% 90%, 99% 94%, 99% 98%, 95% 99%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 10% 99%, 7% 100%, 4% 99%, 2% 97%, 1% 97%, 0% 94%, 1% 89%, 0% 84%, 1% 81%, 0 76%, 0 71%, 1% 66%, 0% 64%, 0% 61%, 0% 59%, 1% 54%, 0% 49%, 1% 45%, 0% 40%, 1% 37%, 0% 34%, 1% 29%, 0% 23%, 2% 20%, 1% 17%, 1% 13%, 0 10%, 1% 6%, 1% 3%);
  }

  .side-btn{

    width: 200px;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background: black;
    background: transparent;
    border: black solid 5px;
    min-width: 250px;
    padding: 10px 25px;
    margin-top: 35px;
    background: #ff0083;
    border: transparent;
    padding: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 100px;
    max-width: 100px;
    min-width: 169px;
    font-size: 14px;
    font-weight: 900;
    color: white;
    font-family: 'Jura', sans-serif;



  }

  .bgwhite{

    background-color: white;
    color:black;
  }